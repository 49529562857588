<template>
  <v-container>
    <v-row>
      <div class="tabs-div">
        <TabNavigation :tabs="tabs" :currentDispatcherId="this.$route.params.id"/>
      </div>
    </v-row>

    <div class="drivers-table">

      <v-row justify="space-between">
        <v-col cols="6">
          <!-- <v-btn-toggle v-model="filters.status" color="primary">
            <v-btn text small value="All" style="height: 50px;">All</v-btn>

            <v-btn text small value="active" style="height: 50px;">
              Active
            </v-btn>
            <v-btn text small value="Assigned" style="height: 50px;">
              Confirmed
            </v-btn>
            <v-btn text small value="InProgress" style="height: 50px;">
              InProgress
            </v-btn>
            <v-btn text small value="Expired" style="height: 50px;">
              Expired
            </v-btn>
            <v-btn text small value="Completed" style="height: 50px;">
              Completed
            </v-btn>
            <v-btn text small value="Cancelled" style="height: 50px;">
              Cancelled
            </v-btn>
          </v-btn-toggle> -->
        </v-col>
        <v-col cols="6" style="margin-top: 8px">
          <v-row>
            <div style="max-width: 300px;">
              <date-filter :init-date="initDate" @dateChange="onDateChange" />
            </div>

            <v-text-field label="Search Vehicles" solo append-icon="mdi-magnify" v-model="filters.search"  style="margin-right:12px"/>
            <!-- <a href="#" @click.prevent="
              exportToCSV()
              ">
              <v-btn color="primary" style="margin:5px; ">
                <v-icon small> mdi-download </v-icon>
                Export
              </v-btn>
            </a> -->
          </v-row>
        </v-col>
      </v-row>

      <v-card class="mt-15">
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Vehicles
            </div>
          </template>
          <v-data-table :headers="headers" :items="requests" :options.sync="tableOptions" :loading="loading"
            :server-items-length="totalItems"
            map-type="roadmap" class="elevation-1" :footer-props="{
              'items-per-page-options': tableOptions.rowsPerPageItems,
            }">
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ getReadableDate(item.createdAt) }}
            </template>

            <template v-slot:[`item.updatedAt`]="{ item }">
              {{ getReadableDate(item.updatedAt) }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip :key="index" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="goToRequest(item._id)">
                    mdi-eye
                  </v-icon>
                </template>
                View
              </v-tooltip>
            </template>
           
          </v-data-table>
        </base-material-card>
      </v-card>
          
   </div>
  </v-container>
</template>

<script>

import {
  getDispatcherRequest,
  getDispatcherVehicles,
} from "@/services/admin";

import DateFilter from "@/components/dateFilter";
import TabNavigation from '@/components/TabNavigation.vue';

function debounce(func, delay) {
  let timer;
  return function (...args) {
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}
export default {
  components: {
    TabNavigation,
    DateFilter,
  },
  data() {
    return {

      tabs: [
        { name: 'General', path: '/dispatcher-general-details' },
        { name: 'Bookings', path: '/dispatcher-bookings' },
        { name: 'Drivers', path: '/dispatcher-drivers' },
        { name: 'Vehicles', path: '/dispatcher-vehicles' },
        { name: 'Transactions', path: '/dispatcher-transactions' }
      ],

      loading: false,
      requests: [],

      filters: {
        startDate: null,
        endDate: null,
        status: "All",
        search: '',
        order: 'DESC'
      },
      statusOptions: ['All', 'Active', 'Pending', 'Incomplete', 'Blocked', 'Deleted'],
      headers: [
        {
          text: "Make",
          align: "start",
          sortable: false,
          value: "make",
        },
        {
          text: "Registration",
          value: "registration",
          sortable: false,
        },
        {
          text: "Type",
          value: "type",
          sortable: false,
        },
        {
          text: "Max Passengers",
          value: "maxPassengers",
          sortable: false,
        },
        {
          text: "Created At",
          value: "createdAt",
          sortable: false,
        },
        {
          text: "updated At",
          value: "updatedAt",
          sortable: false,
        },
        // {
        //   text: "Actions",
        //   value: "actions",
        //   sortable: false,
        //   // width: "100px"
        // },
      ],

      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
        rowsPerPageItems: [10, 30, 50, 100],
      },

      totalItems: 10,

      initDate: [],
      usersStatus: "all",
      stats: null,
      selectedDispatcherId: null

    };
  },
  created() {

  },

  watch: {
    'tableOptions': {
      handler: 'fetchDispatcherVehicles',
      deep: true
    },

    // usersStatus() {
    //   this.filters.status = this.usersStatus
    //   this.fetchDispatcherVehicles();
    // },

    'filters.status': {
      handler(newStatus, oldStatus) {
        if (newStatus !== oldStatus) {
          this.fetchDispatcherVehicles();
        }
      },
      deep: true
    },

    'filters.search': {
      handler: debounce(async function (newValue) {
        this.fetchDispatcherVehicles();
      }, 500),
      immediate: false
    },
  },

  methods: {
    async fetchDispatcherVehicles() {
      try {
        this.loading = true;
        const response = await getDispatcherVehicles({
          ...this.tableOptions,
          ...this.filters,
          take: this.tableOptions.itemsPerPage,
          status: this.filters.status === 'All' ? null : this.filters.status
        }, this.$route.params.id);

        this.requests = response.data.data;

        this.totalItems = response.data.meta.itemCount;
      } catch (error) {
        console.error('Error fetching drivers:', error);
      } finally {
        this.loading = false;
      }
    },

    onDateChange(newValue) {
      // console.log('newValue', newValue);
      if (!newValue || newValue.length !== 2) return;

      // Convert the strings to Date objects
      const startDate = newValue[0] ? new Date(newValue[0]) : null;
      const endDate = newValue[1] ? new Date(newValue[1]) : null;

      // Update the filters with the new start and end dates
      this.filters.startDate = startDate ? startDate.toISOString().split('T')[0] : '';
      this.filters.endDate = endDate ? endDate.toISOString().split('T')[0] : '';

      // Fetch drivers with the updated filters
      this.fetchDispatcherVehicles();
      // this.fetchDispatcherStats();
    },

    getReadableDate(date) {
      const d = new Date(date);
      return d.toUTCString();
    },

    constructId(id) {
      return (id?.slice(17, 19) + "-" + id?.slice(19)).toUpperCase()
    },

  }
}
</script>

<style scoped>
.tabs-div {
  width: 100%;
  padding: 20px;
}

.drivers-table {
  width: 100%;
  padding: 0.7rem;
}
</style>
